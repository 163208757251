import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/organisms/Layout/Layout"
import Seo from "../components/organisms/Seo/Seo"
import Container from "../components/atoms/Container/Container"
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import ProductGrid from '../components/organisms/ProductGrid/ProductGrid'
import SearchForm from '../components/molecules/Search/Search'
import { handleLink } from "../helpers/general"

import ProcessSearch from '../components/molecules/Search/ProcessSearch.js'

const Search = () => {
    const [keyword, setKeyword] = useState('');
    const [results, setResults] = useState({items: [], totals: {count: 0}});
    const [qsChecked, setQSChecked] = useState(false);

    useEffect(() => {
        if (!qsChecked && typeof window !== 'undefined') {
            setQSChecked(true);
            const qs = window.location.search;
            const qsObj = new URLSearchParams(qs);
            const kw = qsObj.get('q');
            if (kw) {
                ProcessSearch(kw, 100).then((results) => {
                    setKeyword(kw);
                    setResults(results)
                })
            } else {
                setResults([{error: true}]);
            }
        }
    }, [qsChecked, setQSChecked, setKeyword, setResults]);
    
    return (
        <Layout>
            <Seo title="Search" />
            <Container size="large">
                {keyword && results.totals.count > 0  && (
                    <PageTitle title={`Search results for "${keyword}"`} />
                )}
                {!keyword && results.items.length === 0 && (
                    <PageTitle title={`Searching...`} />
                )}
                {keyword && results.totals.count === 0 && (
                    <PageTitle title={`No results found!`} />
                )}
                {!keyword && results.items.length > 0 && (
                    <PageTitle title={`Unable to search :(`} />
                )}
                
                {results.items.length > 0 && results.items.map((result, resultIndex) => {
                    // Add logic
                    if ('products' in result && result.products.length > 0) {
                        return <ProductGrid key={resultIndex} products={result.products} />
                    }

                    if ('posts' in result && result.posts.length > 0) {
                        // TODO: Convert to component
                        return (
                            <div key={resultIndex}>
                                {result.posts && (
                                    result.posts.map((post, postIndex) => (
                                        <a key={postIndex} href={post.url}>{post.title}</a>
                                    ))
                                )}
                            </div>
                        )
                    }

                    if ('error' in result) {
                        return (
                            <div key={resultIndex}>
                                Something went wrong, please <Link to={handleLink("/")}>return home</Link>
                            </div>
                        )
                    }

                    return null
                })}

                {keyword && results.totals.count === 0 && (
                    <div className="flex-center">
                        <div>
                            No results found for this search term. Please modify your search term below:
                            <br />
                            <br />
                            <div className="formField">
                                <SearchForm toggleSuggestions={false} />
                            </div>
                        </div>
                    </div>
                )}
            </Container>
        </Layout>
    )
  }
  
  export default Search
  